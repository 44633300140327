import React from "react";

function Footer() {
  return (
    <footer className="bg-gray-900 text-white py-4 px-6 text-center">
      <p>
        &copy; 2024 PawnToQueen. If you liked the product, please upvote it on{" "}
        <a
          className="underline"
          target="_blank"
          href="https://github.com/pawntoqueen/pawntoqueen"
        >
          ProductHunt
        </a>
        . For issues reach out to me{" "}
        <a
          className="underline"
          target="_blank"
          href="https://discord.gg/h3tuAUDBwC"
        >
          here
        </a>
        .
      </p>
    </footer>
  );
}

export default Footer;
