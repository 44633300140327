import { Joystick } from "lucide-react";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { useNavigate } from "react-router-dom";

function HowAndWhatCard() {
  let navigate = useNavigate();

  const loadPuzzle = () => {
    try {
      navigate("/puzzle/1");
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Card className="w-full lg:h-full xl:h-full">
      <CardHeader>
        <CardTitle>How and What?</CardTitle>
      </CardHeader>
      <CardContent className="grid gap-4">
        <div>
          <div className="mb-4 grid grid-cols-[25px_1fr] items-start pb-4 last:mb-0 last:pb-0">
            <span className="flex h-2 w-2 translate-y-1 rounded-full bg-sky-500" />
            <div className="space-y-1">
              <p className="text-sm font-medium leading-none">
                Daily 50 puzzles
              </p>
              <p className="text-sm text-muted-foreground">
                Daily set of 50 puzzles to solve
              </p>
            </div>
          </div>
          <div className="mb-4 grid grid-cols-[25px_1fr] items-start pb-4 last:mb-0 last:pb-0">
            <span className="flex h-2 w-2 translate-y-1 rounded-full bg-sky-500" />
            <div className="space-y-1">
              <p className="text-sm font-medium leading-none">
                One chance a day
              </p>
              <p className="text-sm text-muted-foreground">
                If you get one wrong move, you have to wait for next day to play
                again
              </p>
            </div>
          </div>
          <div className="mb-4 grid grid-cols-[25px_1fr] items-start pb-4 last:mb-0 last:pb-0">
            <span className="flex h-2 w-2 translate-y-1 rounded-full bg-sky-500" />
            <div className="space-y-1">
              <p className="text-sm font-medium leading-none">
                You vs Rest of the World
              </p>
              <p className="text-sm text-muted-foreground">
                The set of puzzle is same for everyone.Share your result.
              </p>
            </div>
          </div>
        </div>
      </CardContent>
      <CardFooter>
        <Button onClick={loadPuzzle} className="w-full">
          <Joystick className="mr-2 h-4 w-4" /> Start
        </Button>
      </CardFooter>
    </Card>
  );
}

export default HowAndWhatCard;
