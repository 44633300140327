import Footer from "@/components/Footer";
import Header from "@/components/Header";
import HowAndWhatCard from "@/components/HowAndWhatCard";
import React from "react";

function HowToPlayScreen() {
  return (
    <>
      <Header />
      <div className="">
        <div className="h-[80vh] items-center justify-center">
          <div className="h-4"></div>
          <HowAndWhatCard />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default HowToPlayScreen;
