import React from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Button } from "./ui/button";
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuIndicator,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  NavigationMenuViewport,
} from "@/components/ui/navigation-menu";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

export default function Header() {
  const navigate = useNavigate();

  const logout = () => {
    localStorage.removeItem("accessToken");
    navigate("/login");
  };

  return (
    <header className="bg-[#0E1428] text-white py-4 px-6">
      <div className="flex-1 flex justify-center"></div>
      <nav className="flex-1 md:flex justify-center hidden lg:visible xl:visible md:visible">
        <NavigationMenu>
          <NavigationMenuList>
            <NavigationMenuItem>
              <NavigationMenuTrigger className="bg-inherit">
                {!localStorage.getItem("accessToken") ? (
                  <Link to="/login" className="hover:underline ">
                    Login
                  </Link>
                ) : (
                  <DropdownMenu>
                    <DropdownMenuTrigger>
                      <Link to="/dash" className="hover:underline ">
                        Profile Menu
                      </Link>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent>
                      <DropdownMenuLabel>Hey...</DropdownMenuLabel>
                      <DropdownMenuSeparator />
                      <DropdownMenuItem onClick={() => navigate("/dash")}>
                        Dashboard
                      </DropdownMenuItem>
                      <DropdownMenuItem onClick={logout}>
                        Logout
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                )}
              </NavigationMenuTrigger>
            </NavigationMenuItem>
            <NavigationMenuItem>
              <NavigationMenuTrigger className="bg-inherit">
                <Link to="/" className="text-[#7B9E89] text-4xl font-[Inter]">
                  <h1>PawnToQueen</h1>
                </Link>
              </NavigationMenuTrigger>
            </NavigationMenuItem>
            <NavigationMenuItem>
              <NavigationMenuTrigger className="bg-inherit">
                <Link to="/howto">How to play?</Link>
              </NavigationMenuTrigger>
            </NavigationMenuItem>
          </NavigationMenuList>
        </NavigationMenu>
      </nav>

      <div className="flex justify-between lg:hidden xl:hidden md:hidden">
        <div>
          <Link to="/" className="text-[#7B9E89] text-4xl font-[Inter]">
            <h1>PawnToQueen</h1>
          </Link>
        </div>
        <div>
          <Popover>
            <PopoverTrigger className="text-white">
              <div>
                <svg
                  width="24"
                  height="24"
                  className="my-auto justify-center mt-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="#FFFFFF"
                >
                  <path d="M8.5 7.5h12a1.5 1.5 0 1 0 0-3h-12a1.5 1.5 0 1 0 0 3ZM20.5 10.5h-12a1.5 1.5 0 1 0 0 3h12a1.5 1.5 0 1 0 0-3ZM20.5 16.5h-12a1.5 1.5 0 1 0 0 3h12a1.5 1.5 0 1 0 0-3ZM4.89 5.43c-.04-.1-.09-.18-.14-.26a1.45 1.45 0 0 0-.42-.42l-.26-.14-.28-.08a1.42 1.42 0 0 0-.58 0l-.28.08-.26.14a1.45 1.45 0 0 0-.42.42c-.05.08-.1.16-.14.26l-.08.28a1.56 1.56 0 0 0 .08.86 1.51 1.51 0 0 0 2.45.49A1.51 1.51 0 0 0 5 6c0-.1-.01-.2-.03-.29l-.08-.28ZM4.89 11.43a2.15 2.15 0 0 0-.14-.26l-.19-.23a1.45 1.45 0 0 0-.49-.33l-.28-.08a1.42 1.42 0 0 0-.58 0l-.28.08-.26.14A1.52 1.52 0 0 0 2 12a1.52 1.52 0 0 0 .93 1.39 1.56 1.56 0 0 0 1.14 0 1.51 1.51 0 0 0 .9-1.68l-.08-.28ZM2.44 16.94a1.5 1.5 0 1 0 2.12 0 1.55 1.55 0 0 0-2.12 0Z"></path>
                </svg>
              </div>
            </PopoverTrigger>
            <PopoverContent>
              <Button className="pt-2 bg-inherit text-white hover:bg-inherit">
                {!localStorage.getItem("accessToken") ? (
                  <Link to="/login" className="hover:underline ">
                    Login
                  </Link>
                ) : (
                  <Link to="/dash" className="hover:underline ">
                    Dashboard
                  </Link>
                )}
              </Button>
              <hr />
              <Button className="pt-2 bg-inherit text-white hover:bg-inherit">
                <Link to="/howto" className="hover:underline">
                  How to play?
                </Link>
              </Button>
              <hr />
            </PopoverContent>
          </Popover>
        </div>
      </div>
      <div className="flex-1" />
    </header>
  );
}
