function Loading() {
  return (
    <div>
      <div className="fixed inset-0 z-50 overflow-y-auto">
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-gray-500 opacity-20"></div>
          </div>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
            &#8203;
          </span>
          <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-white sm:mx-0 sm:h-10 sm:w-10">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
                    <circle
                      fill="#121213"
                      stroke="#121213"
                      strokeWidth="15"
                      r="15"
                      cx="40"
                      cy="65"
                      className="--darkreader-inline-stroke: #ff6dff; --darkreader-inline-fill: #6d006d;"
                      data-darkreader-inline-stroke=""
                      data-darkreader-inline-fill=""
                    >
                      <animate
                        attributeName="cy"
                        calcMode="spline"
                        dur="1.6"
                        values="65;135;65;"
                        keySplines=".5 0 .5 1;.5 0 .5 1"
                        repeatCount="indefinite"
                        begin="-.4"
                      ></animate>
                    </circle>
                    <circle
                      fill="#121213"
                      stroke="#121213"
                      strokeWidth="15"
                      r="15"
                      cx="100"
                      cy="65"
                      className="--darkreader-inline-stroke: #ff6dff; --darkreader-inline-fill: #6d006d;"
                      data-darkreader-inline-stroke=""
                      data-darkreader-inline-fill=""
                    >
                      <animate
                        attributeName="cy"
                        calcMode="spline"
                        dur="1.6"
                        values="65;135;65;"
                        keySplines=".5 0 .5 1;.5 0 .5 1"
                        repeatCount="indefinite"
                        begin="-.2"
                      ></animate>
                    </circle>
                    <circle
                      fill="#121213"
                      stroke="#121213"
                      strokeWidth="15"
                      r="15"
                      cx="160"
                      cy="65"
                      className="--darkreader-inline-stroke: #ff6dff; --darkreader-inline-fill: #6d006d;"
                      data-darkreader-inline-stroke=""
                      data-darkreader-inline-fill=""
                    >
                      <animate
                        attributeName="cy"
                        calcMode="spline"
                        dur="1.6"
                        values="65;135;65;"
                        keySplines=".5 0 .5 1;.5 0 .5 1"
                        repeatCount="indefinite"
                        begin="0"
                      ></animate>
                    </circle>
                  </svg>
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Loading...
                  </h3>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Hold your <a className="line-through">horses</a>{" "}
                      knights...
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Loading;
