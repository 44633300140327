import { Chess, Square } from "chess.js";
import { useEffect, useState } from "react";
import ReactConfetti from "react-confetti";
import MovesPlayed from "./MovesPlayed";
import SolvedModal from "./SolvedModal";
import axios from "axios";
import FailedModal from "./FailedModal";
import Loading from "./Loading";
import PuzzleThemesBox from "./PuzzleThemesBox";
import RatingBox from "./RatingBox";
import { Card, CardHeader, CardContent } from "./ui/card";
import { Chessboard } from "react-chessboard";

interface PuzzleProps {
  id: string;
  fen: string;
  rating: number;
  moves: string[];
  themes: string;
}

function Puzzle(props: { id: string }) {
  const [game, setGame] = useState<Chess>(new Chess());
  const [fen, setFen] = useState(game.fen());
  const [legalMoves, setLegalMoves] = useState<Square[]>([]);
  const [selectedSquare, setSelectedSquare] = useState(null);
  const [moves, setMoves] = useState<string[]>([]);
  let [puzzleMoveNumber, setPuzzleMoveNumber] = useState<number>(1);
  const [movesPlayed, setMovesPlayed] = useState([""]);
  const [solved, setSolved] = useState(false);
  const [failed, setFailed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [firstMove, setFirstMove] = useState("white");
  const [puzzleData, setPuzzleData] = useState<PuzzleProps>({
    id: "",
    fen: "",
    rating: 0,
    moves: [],
    themes: "",
  });

  const validateMove = (from: string, to: string) => {
    let move = from + to;
    if (moves[puzzleMoveNumber - 1].length === 5) {
      move += game.history()[puzzleMoveNumber - 1][3].toLowerCase();
    }

    if (move === moves[puzzleMoveNumber - 1]) {
      console.log("move is valid, ", move);
      return true;
    }
    return false;
  };

  const computerMove = async () => {
    if (puzzleMoveNumber % 2 == 0) {
      try {
        setTimeout(() => {
          let moveToPlay = moves[puzzleMoveNumber];
          moveToPlay.length > 4
            ? makeMove(
                moveToPlay.substring(0, 2),
                moveToPlay.substring(2, 4),
                moveToPlay.substring(4, 5),
              )
            : makeMove(moveToPlay.substring(0, 2), moveToPlay.substring(2, 4));
          setFen(game.fen());
          setMovesPlayed(game.history());
        }, 1000);
      } catch (err) {
        console.log("error in computerMove: ", err);
      }
    }
  };

  const loadPuzzle = async (puzzleNumber: string) => {
    try {
      setIsLoading(true);
      const responsePuzzle = await axios
        .post<PuzzleProps>(
          `${import.meta.env.VITE_SERVER_URL}/api/v1/puzzles`,
          {
            requestId: localStorage.getItem("requestId"),
            id: puzzleNumber,
          },
        )
        .then((response) => {
          let responseData: PuzzleProps = response.data;
          return responseData;
        });
      game.load(responsePuzzle.fen);
      game.turn() === "w" ? setFirstMove("black") : setFirstMove("white");
      setPuzzleData(responsePuzzle);
      setFen(game.fen());
      setMoves(responsePuzzle.moves);
      setIsLoading(false);
      setTimeout(() => {
        makeMove(
          responsePuzzle.moves[0].substring(0, 2),
          responsePuzzle.moves[0].substring(2, 4),
          responsePuzzle.moves[0][4],
        );
        setPuzzleMoveNumber(1);
        setMovesPlayed(game.history());
      }, 500);
    } catch (err) {
      console.log(err);
    }
  };

  const closeModal = () => {
    setSolved(false);
  };

  const handlePromotionCheck = (
    piece,
    promoteFromSquare,
    promoteToSquare,
  ): boolean => {
    console.log(promoteFromSquare, promoteToSquare);
    return makeMove(promoteFromSquare, promoteToSquare, piece);
  };

  const handlePieceDrop = (sourceSquare, targetSquare): boolean => {
    const moveMade = makeMove(sourceSquare, targetSquare);
    if (moveMade === false) {
      return false;
    }
    const isvalid = validateMove(sourceSquare, targetSquare);
    if (!isvalid) {
      setFailed(true);
      return;
    }
    if (moves.length === puzzleMoveNumber) {
      setSolved(true);
      localStorage.setItem("puzzleNumber", `${props.id}`);
      return true;
    }
    setMovesPlayed(game.history());
    computerMove();
    return true;
  };

  const handleSquareClick = (square) => {
    if (selectedSquare) {
      if (selectedSquare === square) {
        setSelectedSquare(null); // Deselect if the same square is clicked again
        setLegalMoves([]);
      } else {
        const moveMade = makeMove(selectedSquare, square);
        if (moveMade) {
          setSelectedSquare(null); // Clear selection if a move was made
          setLegalMoves([]);
          setMovesPlayed(game.history());
          const isvalid = validateMove(selectedSquare, square);
          if (!isvalid) {
            setFailed(true);
            return;
          }
          if (moves.length === puzzleMoveNumber) {
            setSolved(true);
            localStorage.setItem("puzzleNumber", `${props.id}`);
            return;
          }
          computerMove(); // computer move
        } else {
          setSelectedSquare(null); // Update selection if the move was invalid
          setLegalMoves([]);
        }
      }
    } else {
      // Select the square if it contains a piece that matches the current turn
      const piece = game.get(square);
      if (piece && piece.color === game.turn()) {
        setSelectedSquare(square);
        updateLegalMoves(square);
      }
    }
  };

  const makeMove = (from, to, piece = "q") => {
    try {
      // console.log("piece is ", piece);
      // if (piece === "wQ" || piece === "bQ") {
      //   piece = "q";
      // }
      // if (piece === "wR" || piece === "bR") {
      //   piece = "r";
      // }
      // if (piece === "wB" || piece === "bB") {
      //   piece = "b";
      // }
      // if (piece === "wN" || piece === "bN") {
      //   piece = "n";
      // }
      // console.log("piece is ", piece, from, to);
      // game.move({
      //   from: from,
      //   to: to,
      //   promotion: piece, // Always promote to a queen for simplicity
      // });
      if (piece.length > 1) {
        piece = piece[1].toLowerCase();
      }
      const move = game.move({
        from: from,
        to: to,
        promotion: piece, // Always promote to a queen for simplicity
      });
      if (move === null) {
        return false; // Illegal move
      }
      setFen(game.fen());
      setPuzzleMoveNumber(++puzzleMoveNumber);
      return true;
    } catch (err) {
      // console.log(err);
      return false;
    }
  };

  const updateLegalMoves = (square: Square) => {
    const movesAllowed = game
      .moves({ square, verbose: true })
      .map((move) => move.to);
    setLegalMoves(movesAllowed);
  };

  const customSquareStyles = () => {
    const highlightStyles = {};
    if (selectedSquare) {
      highlightStyles[selectedSquare] = {
        backgroundColor: "rgba(255, 255, 0, 0.4)",
      };
      legalMoves.forEach((move) => {
        highlightStyles[move] = {
          backgroundColor: "rgba(0, 0, 0, 0.4)",
          borderRadius: "50%",
        };
      });
    }
    return highlightStyles;
  };

  useEffect(() => {
    if (props.id) {
      setSolved(false);
      loadPuzzle(props.id);
    }
  }, [props.id]);

  return (
    <div className="w-full">
      {solved ? (
        <div className="self-center items-center justify-center w-full">
          <ReactConfetti className="w-full" tweenDuration={5000} />
        </div>
      ) : (
        <></>
      )}
      {isLoading ? (
        <div className="self-center items-center justify-center w-[100vh]">
          <Loading />
        </div>
      ) : (
        <div></div>
      )}
      <div
        className="lg:grid lg:grid-cols-10 lg:gap-2 lg:w-[100vw]
                      xl:grid xl:grid-cols-10 xl:gap-2  
                      sm:grid-row-3
                      "
      >
        <div className="col-span-2 w-full mx-auto justify-center items-center">
          <Card className="w-full lg:h-screen xl:h-screen my-2">
            <CardContent className="pt-6 ">
              <div className="flex bg-[#778da9] justify-center items-center">
                <div>
                  {game?.turn() === "w" ? (
                    <img
                      src={"/images/pieces/pw.png"}
                      className=" flex w-[90px] h-[90px] justify-center"
                    />
                  ) : (
                    <img
                      src={"/images/pieces/pb.png"}
                      className="flex w-[90px] h-[90px] justify-center"
                    />
                  )}
                </div>
                <div className="text-center">
                  {movesPlayed.length % 2 == 1 || movesPlayed.length === 0
                    ? "Player "
                    : "Computer "}
                  to move
                </div>
              </div>
            </CardContent>
          </Card>
        </div>

        <div className="xl:col-span-5 lg:col-span-5 md:col-span-5 lg:h-full justify-center items-center content-center">
          {solved ? (
            <SolvedModal
              puzzleNumber={parseInt(props.id)}
              onClose={closeModal}
            />
          ) : (
            <div></div>
          )}
          {failed ? (
            <FailedModal
              puzzleNumber={parseInt(props.id)}
              onClose={closeModal}
            />
          ) : (
            <div></div>
          )}
          <Card className="xl:px-10 lg:px-10 lg:h-screen">
            <CardContent className="pt-6 justify-center items-center">
              <Chessboard
                position={fen}
                onPieceDrop={handlePieceDrop}
                onSquareClick={handleSquareClick}
                customSquareStyles={customSquareStyles()}
                // onPromotionPieceSelect={handlePromotionCheck}
                boardOrientation={firstMove === "white" ? "white" : "black"}
              />
            </CardContent>
          </Card>
        </div>
        <div className="col-span-2 my-2">
          <Card className="w-full lg:h-screen xl:h-screen">
            <CardContent>
              <MovesPlayed history={movesPlayed} />

              <PuzzleThemesBox themes={puzzleData?.themes} />
              <RatingBox rating={puzzleData?.rating} />
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default Puzzle;
