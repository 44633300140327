import { DatePickerDemo } from "@/components/DatePicker";
import Footer from "@/components/Footer";
import Header from "@/components/Header";
import axios from "axios";
import React, { useEffect, useState } from "react";

function DashScreen() {
  const [streak, setStreak] = useState(0);
  useEffect(() => {
    console.log("DashScreen mounted");
    const checkLogin = localStorage.getItem("accessToken");
    if (!checkLogin) {
      console.log("Not logged in");
      window.location.href = "/login";
    }
    const getStreak = async () => {
      const userId = localStorage.getItem("user");
      const res = await axios
        .get(`${import.meta.env.VITE_SERVER_URL}/api/v1/getStreak/${userId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        })
        .then((res) => res.data);
      console.log(res);
      setStreak(res);
    };
    getStreak();
  }, []);
  return (
    <div className="h-screen">
      <Header />
      <div className="w-full h-screen flex flex-col justify-center items-center">
        <h1 className="text-3xl">Welcome to PawnToqueen</h1>
        <div className="text-md mt-10">
          {streak != 0
            ? `your current streak is ${streak} 🔥`
            : "You don't have any streak yet.."}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default DashScreen;
