// function to copy messgage to clipboard, the message should be a string having the puzzle number solved and date in dd/mm format
export const shareMessageComposer = (puzzleNumber: number) => {
  let message = `I just solved a puzzle on ${new Date().toLocaleDateString()} with the puzzle number ${puzzleNumber}`;
  if (localStorage.getItem("lastPlayedDate") != null)
    message = `PawnToQueen ~ ${new Date(
      localStorage.getItem("lastPlayedDate"),
    ).toLocaleDateString()} \n`;
  let emojiNumber = "";
  puzzleNumber != 50 ? (puzzleNumber -= 1) : "";
  puzzleNumber === 0 ? (message += "oops better luck tomorrow! 🤡\n") : "";
  puzzleNumber != 50 && puzzleNumber > 40
    ? (message += "oof, you were almost there! 😅\n")
    : "";
  puzzleNumber === 50 ? (message += "You're a genius! Congrats! 🎉\n") : "";
  let firstDigit = puzzleNumber % 10;
  let secondDigit = puzzleNumber / 10;
  secondDigit = Math.trunc(secondDigit);

  switch (secondDigit) {
    case 0:
      emojiNumber = emojiNumber + "0️⃣";
      break;
    case 1:
      emojiNumber = emojiNumber + "1️⃣";
      break;
    case 2:
      emojiNumber = emojiNumber + "2️⃣";
      break;
    case 3:
      emojiNumber = emojiNumber + "3️⃣";
      break;
    case 4:
      emojiNumber = emojiNumber + "4️⃣";
      break;
    case 5:
      emojiNumber = emojiNumber + "5️⃣";
      break;
    case 6:
      emojiNumber = emojiNumber + "6️⃣";
      break;
    case 7:
      emojiNumber = emojiNumber + "7️⃣";
      break;
    case 8:
      emojiNumber = emojiNumber + "8️⃣";
      break;
    case 9:
      emojiNumber = emojiNumber + "9️⃣";
      break;
  }
  switch (firstDigit) {
    case 0:
      emojiNumber = emojiNumber + "0️⃣";
      break;
    case 1:
      emojiNumber = emojiNumber + "1️⃣";
      break;
    case 2:
      emojiNumber = emojiNumber + "2️⃣";
      break;
    case 3:
      emojiNumber = emojiNumber + "3️⃣";
      break;
    case 4:
      emojiNumber = emojiNumber + "4️⃣";
      break;
    case 5:
      emojiNumber = emojiNumber + "5️⃣";
      break;
    case 6:
      emojiNumber = emojiNumber + "6️⃣";
      break;
    case 7:
      emojiNumber = emojiNumber + "7️⃣";
      break;
    case 8:
      emojiNumber = emojiNumber + "8️⃣";
      break;
    case 9:
      emojiNumber = emojiNumber + "9️⃣";
      break;
  }
  message = message + emojiNumber;
  message = message + " ♟️ solved";

  navigator.clipboard.writeText(message);
};
// 0️⃣1️⃣2️⃣3️⃣4️⃣5️⃣6️⃣7️⃣8️⃣9️⃣🔟
