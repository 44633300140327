import { ToggleGroup, ToggleGroupItem } from "@/components/ui/toggle-group";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";

function PuzzleThemesBox(props: { themes: string }) {
  return (
    <Card className="mb-4">
      <CardHeader>
        <CardTitle>Themes</CardTitle>
      </CardHeader>
      <CardContent>
        {/* <ScrollArea className="pb-4"> */}
        <ToggleGroup
          variant="outline"
          type="single"
          className="pb-4 w-full overflow-x-scroll"
        >
          {props.themes.split(" ").map((theme, index) => {
            return (
              <ToggleGroupItem
                key={theme}
                value={theme}
                aria-label={"Toggle " + index}
              >
                {theme}
              </ToggleGroupItem>
            );
          })}
        </ToggleGroup>
      </CardContent>
    </Card>
  );
}

export default PuzzleThemesBox;
