import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import "./index.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import PuzzleScreen from "./screens/PuzzleScreen.tsx";
import * as Sentry from "@sentry/react";
import LoginScreen from "./screens/LoginScreen.tsx";
import HowToPlayScreen from "./screens/HowToPlayScreen.tsx";
import NotFound from "./screens/NotFound.tsx";
import DashScreen from "./screens/Dashboard/DashScreen.tsx";

Sentry.init({
  environment: import.meta.env.VITE_ENV,
  dsn: "https://19193f7d6fea41aff9d2f768903cd6a0@o4507395965190144.ingest.us.sentry.io/4507396737138688",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    /^https:\/\/chess.halfstackengineer\.xyz\//,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.05, // This sets the sample rate at 5%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
  {
    path: "puzzle/:id",
    element: <PuzzleScreen />,
  },
  {
    path: "login",
    element: <LoginScreen />,
  },
  {
    path: "howto",
    element: <HowToPlayScreen />,
  },
  {
    path: "dash",
    element: <DashScreen />,
  },
]);

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);
