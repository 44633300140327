import { Chess, Move, Square } from "chess.js";
import { useEffect, useState } from "react";
import ReactConfetti from "react-confetti";
import MovesPlayed from "../components/MovesPlayed";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";
import HowAndWhatCard from "@/components/HowAndWhatCard";
import { Chessboard } from "react-chessboard";
import axios from "axios";

function StartPuzzle() {
  const [game, setGame] = useState<Chess>(new Chess());
  const [fen, setFen] = useState(game.fen());
  const [legalMoves, setLegalMoves] = useState<Square[]>([]);
  const [checkmate, setCheckmate] = useState(false);
  const [movesPlayed, setMovesPlayed] = useState([""]);
  const [selectedSquare, setSelectedSquare] = useState(null);

  let navigate = useNavigate();

  const loadPuzzle = () => {
    try {
      navigate("/puzzle/1");
    } catch (err) {
      console.log(err);
    }
  };

  const handlePieceDrop = (sourceSquare, targetSquare): boolean => {
    return makeMove(sourceSquare, targetSquare);
  };

  const handlePromotionCheck = (
    piece,
    promoteFromSquare,
    promoteToSquare,
  ): boolean => {
    return makeMove(promoteFromSquare, promoteToSquare, piece);
  };

  const handleSquareClick = (square) => {
    if (selectedSquare) {
      if (selectedSquare === square) {
        setSelectedSquare(null); // Deselect if the same square is clicked again
        updateLegalMoves([]);
      } else {
        const moveMade = makeMove(selectedSquare, square);
        if (moveMade) {
          setSelectedSquare(null); // Clear selection if a move was made
          setMovesPlayed(game.history());
          game.isCheckmate() ? setCheckmate(true) : setCheckmate(false);
          updateLegalMoves([]);
        } else {
          setSelectedSquare(square); // Update selection if the move was invalid
          updateLegalMoves(square);
        }
      }
    } else {
      // Select the square if it contains a piece that matches the current turn
      const piece = game.get(square);
      if (piece && piece.color === game.turn()) {
        setSelectedSquare(square);
        updateLegalMoves(square);
      }
    }
  };

  const makeMove = (from, to, piece = "q") => {
    if (piece !== "q") {
      piece = piece[1].toLowerCase();
    }
    // const move = game.move({
    //   from: from,
    //   to: to,
    //   promotion: piece, // Always promote to a queen for simplicity
    // });

    // if (move === null) {
    //   return false; // Illegal move
    // }
    try {
      game.move({
        from: from,
        to: to,
        promotion: piece, // Always promote to a queen for simplicity
      });
    } catch (err) {
      return false;
    }
    setFen(game.fen());
    setMovesPlayed(game.history());
    return true; // Move made
  };

  const updateLegalMoves = (square) => {
    const moves = game.moves({ square, verbose: true }).map((move) => move.to);
    setLegalMoves(moves);
  };

  const customSquareStyles = () => {
    const highlightStyles = {};
    if (selectedSquare) {
      highlightStyles[selectedSquare] = {
        backgroundColor: "rgba(255, 255, 0, 0.4)",
      };
      legalMoves.forEach((move) => {
        highlightStyles[move] = {
          backgroundColor: "rgba(0, 0, 0, 0.4)",
          borderRadius: "50%",
        };
      });
    }
    return highlightStyles;
  };

  // TBD: fen doesn't updates when the board is reset
  // const resetBoard = () => {
  //   setGame(new Chess());
  //   setFen(game.fen());
  //   setCheckmate(false);
  //   setSelectedSquare(null);
  // };
  useEffect(() => {
    const enterUser = async () => {
      try {
        let response = await axios.post(`${import.meta.env.VITE_SERVER_URL}/`, {
          requestId: localStorage.getItem("requestId"),
          message: "onHomePage",
        });
      } catch (err) {
        // console.log(err);
      }
    };
    enterUser();
  }, []);

  return (
    <div className="w-full">
      {checkmate ? (
        <div className="self-center items-center justify-center w-[100vw]">
          <ReactConfetti tweenDuration={5000} />
        </div>
      ) : (
        <div></div>
      )}
      <div
        className="lg:grid lg:grid-cols-10 lg:gap-2 lg:w-[100vw]
                      xl:grid xl:grid-cols-10 xl:gap-2  
                      sm:grid-row-3
                      "
      >
        <div className="col-span-2 mx-auto py-2 justify-center items-center">
          <HowAndWhatCard />
        </div>

        <div className="xl:col-span-5 lg:col-span-5 md:col-span-5 py-2">
          <Card className="xl:px-10 lg:px-10">
            {/* <CardHeader> */}
            {/* <CardTitle> */}
            {/* Play */}
            {/* {checkmate ? (
                  <Button
                    className="bg-[#0E1428] mx-10 text-white hover:bg-zinc-900 py-2 px-4 rounded"
                    onClick={resetBoard}
                  >
                    Reset Board
                  </Button>
                ) : (
                  <></>
                )} */}
            {/* </CardTitle> */}
            {/* </CardHeader> */}
            <CardContent className="pt-6 justify-center items-center lg:h-screen xl:h-screen">
              <Chessboard
                position={fen}
                onPieceDrop={handlePieceDrop}
                onSquareClick={handleSquareClick}
                onPromotionPieceSelect={handlePromotionCheck}
                customSquareStyles={customSquareStyles()}
              />
            </CardContent>
          </Card>
        </div>
        <div className="col-span-2 lg:pt-2 xl:pt-2">
          <Card>
            <CardContent className="lg:h-screen xl:h-screen">
              <MovesPlayed history={movesPlayed} />
              <div className="h-4"></div>
              <div className="content-center mx-auto bg-[#778da9] lg:w-[100px] lg:h-[100px]">
                {game?.turn() === "w" ? (
                  <img
                    src={"images/pieces/pw.png"}
                    className=" flex w-[90px] h-[90px] justify-center mx-auto"
                  />
                ) : (
                  <img
                    src={"images/pieces/pb.png"}
                    className="flex w-[90px] h-[90px] justify-center mx-auto"
                  />
                )}{" "}
                <br />
              </div>
              <div className="text-center">
                {game?.turn() === "w" ? "White " : "Black "} to play
              </div>
              <div>
                <center>
                  <br></br>
                  <a
                    href="https://www.producthunt.com/posts/pawntoqueen?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-pawntoqueen"
                    target="_blank"
                  >
                    <img
                      src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=469143&theme=dark"
                      alt="PawnToQueen - Checkmate&#0032;Your&#0032;Day&#0033; | Product Hunt"
                      className="w-[250px]; h-[54px]"
                      width="250"
                      height="54"
                    />
                  </a>{" "}
                </center>
                <br></br>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default StartPuzzle;
