import React, { useEffect, useRef } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./ui/table";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { ScrollArea } from "./ui/scroll-area";
import { ToggleGroup, ToggleGroupItem } from "./ui/toggle-group";

function MovesPlayed(props: { history: string[] }) {
  const size = 2;
  let chunkedArray: string[][] = [];
  for (let i = 0; i < props.history.length; i += size) {
    chunkedArray.push(props.history.slice(i, i + size));
  }
  // const toBottomRef = useRef<any>(null);
  // const scrollToBottom = () => {
  //   toBottomRef?.current?.scrollIntoView({
  //     behavior: "smooth",
  //   });
  //   // const { scrollHeight, clientHeight } = toBottomRef.current;
  //   // toBottomRef.current.scrollTop = scrollHeight - clientHeight;
  //   console.log(toBottomRef?.current);
  // };
  // useEffect(() => {
  //   scrollToBottom();
  // }, [props.history]);
  return (
    <Card className="border-0 h-100">
      <CardHeader>
        <CardTitle>Moves</CardTitle>
      </CardHeader>
      <CardContent>
        <ScrollArea className="h-80 w-full rounded-md">
          <ToggleGroup variant="outline" type="single">
            <Table className="">
              <TableBody id="moves-table">
                {chunkedArray.map((row, rowIndex) => (
                  <TableRow key={rowIndex}>
                    <TableCell key={rowIndex + 1000} width={10}>
                      {rowIndex + 1}.
                    </TableCell>
                    {row.map((item, colIndex) => (
                      <TableCell key={item} className="w-1/2">
                        {" "}
                        <ToggleGroupItem
                          className="w-full"
                          value={item}
                          aria-label={item}
                        >
                          {item}{" "}
                        </ToggleGroupItem>
                      </TableCell>
                    ))}
                    {row.length < 2 && <TableCell></TableCell>}
                  </TableRow>
                ))}
                {/* <div ref={toBottomRef} /> */}
              </TableBody>
            </Table>
          </ToggleGroup>
        </ScrollArea>
      </CardContent>
    </Card>
  );
}

export default MovesPlayed;
