import { useNavigate } from "react-router-dom";
import { toast, useToast } from "./ui/use-toast";
import { shareMessageComposer } from "@/lib/shareMessageComposer";
import { Button } from "./ui/button";

function SolvedModal(props: { puzzleNumber: number; onClose: any }) {
  const navigate = useNavigate();
  const { toast } = useToast();

  return (
    <div>
      <div className="fixed inset-0 z-50 overflow-y-auto">
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-gray-500 opacity-20"></div>
          </div>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
            &#8203;
          </span>
          <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                  <svg
                    className="h-6 w-6 text-green-600"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    {" "}
                  </svg>
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Nice!
                  </h3>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      You have solved this puzzle!
                    </p>
                  </div>
                  {props.puzzleNumber === 50 ? (
                    <div className="mt-4">
                      <Button className="inline-flex justify-center rounded-md border border-transparent bg-green-100 px-4 py-2 text-sm font-medium text-green-800 hover:bg-green-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                        Hurray! You're a genius! 🎉 Done for the day!
                      </Button>
                      <br />
                      <br />
                      <Button
                        onClick={() => {
                          shareMessageComposer(props.puzzleNumber);
                          toast({
                            title: "Result copied to clipboard",
                            description: "Come back tomorrow!",
                          });
                        }}
                        className="inline-flex justify-center rounded-md border border-transparent bg-green-100 px-4 py-2 text-sm font-medium text-green-800 hover:bg-green-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                      >
                        Share it with the world!
                      </Button>
                    </div>
                  ) : (
                    <div className="mt-4">
                      <Button
                        onClick={() => {
                          props.onClose();
                          navigate(`/puzzle/${props.puzzleNumber + 1}`, {
                            replace: true,
                          });
                        }}
                        className="inline-flex justify-center rounded-md border border-transparent bg-green-100 px-4 py-2 text-sm font-medium text-green-800 hover:bg-green-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                      >
                        Play Next #{props.puzzleNumber + 1}
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SolvedModal;
