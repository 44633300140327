import Footer from "@/components/Footer";
import Header from "@/components/Header";
import { Button } from "@/components/ui/button";
import { BellRing, Check, Loader } from "lucide-react";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { useEffect, useState } from "react";
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSlot,
} from "@/components/ui/input-otp";
import { REGEXP_ONLY_DIGITS_AND_CHARS } from "input-otp";
import axios from "axios";
import { verify } from "crypto";
import { Navigate, useNavigate } from "react-router-dom";
import { set } from "date-fns";
import Loading from "@/components/Loading";

function LoginScreen() {
  const [otpScreen, setOtpScreen] = useState(false);
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const sendOtp = async () => {
    const requestId = localStorage.getItem("requestId");
    setLoading(true);
    const response = await axios
      .post(`${import.meta.env.VITE_SERVER_URL}/api/v1/otp`, {
        email: email,
        requestId,
      })
      .then((res) => {
        setLoading(false);
        return res.data;
      });
    console.log("Send OTP");
    setOtpScreen(true);
  };

  const verifyOtp = async () => {
    const requestId = localStorage.getItem("requestId");
    setLoading(true);
    const response = await axios.post(
      `${import.meta.env.VITE_SERVER_URL}/api/v1/verify`,
      {
        email: email,
        otp: otp,
        requestId,
      },
    );
    if (response.data.token) {
      console.log("Token received");
      localStorage.setItem("accessToken", response.data.token);
      localStorage.setItem("user", response.data.user);
      setLoading(false);
      navigate("/dash");
    }
  };
  useEffect(() => {
    console.log("DashScreen mounted");
    const checkLogin = localStorage.getItem("accessToken");
    if (checkLogin) {
      console.log("already logged in");
      navigate("/dash");
    }
  }, []);
  return (
    <>
      <Header />
      <div className="w-full">
        <div className="w-full h-[80vh] flex flex-col justify-center items-center text-3xl">
          <Card className="w-[340px] justify-center items-center">
            <CardHeader>
              {otpScreen ? (
                <CardTitle>Enter OTP</CardTitle>
              ) : (
                <CardTitle>Login/Sign-up</CardTitle>
              )}
            </CardHeader>
            <CardContent className="grid gap-4">
              {otpScreen ? (
                <CardDescription>Enter otp received on mail</CardDescription>
              ) : (
                <CardDescription>Enter your email</CardDescription>
              )}
              {otpScreen ? (
                <div className="flex">
                  <InputOTP
                    maxLength={6}
                    onChange={(e) => setOtp(e)}
                    pattern={REGEXP_ONLY_DIGITS_AND_CHARS}
                  >
                    <InputOTPGroup>
                      <InputOTPSlot index={0} />
                      <InputOTPSlot index={1} />
                      <InputOTPSlot index={2} />
                      <InputOTPSlot index={3} />
                      <InputOTPSlot index={4} />
                      <InputOTPSlot index={5} />
                    </InputOTPGroup>
                  </InputOTP>
                  <div
                    className="my-auto pl-2 text-right text-muted-foreground text-sm hover:underline"
                    onClick={() => setOtpScreen(false)}
                  >
                    Resend?
                  </div>
                </div>
              ) : (
                <Input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="email@email.com"
                  name="username"
                />
              )}
            </CardContent>
            <CardFooter>
              {otpScreen ? (
                <Button className="w-full" onClick={verifyOtp}>
                  Login {loading && <Loader />}
                </Button>
              ) : (
                <Button className="w-full" onClick={sendOtp}>
                  Send OTP {loading && <Loader />}
                </Button>
              )}
            </CardFooter>
          </Card>
        </div>{" "}
      </div>
      <Footer />
    </>
  );
}

export default LoginScreen;
