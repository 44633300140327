import { ToggleGroup, ToggleGroupItem } from "@/components/ui/toggle-group";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";

function RatingBox(props: { rating: number }) {
  return (
    <Card>
      <CardHeader>
        <CardTitle>Rating</CardTitle>
      </CardHeader>
      <CardContent>
        <ToggleGroup variant="outline" type="multiple">
          <ToggleGroupItem value="Rating" aria-label="Toggle Rating">
            {props.rating}
          </ToggleGroupItem>
        </ToggleGroup>
      </CardContent>
    </Card>
  );
}

export default RatingBox;
