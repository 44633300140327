import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Puzzle from "../components/Puzzle";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "@/components/Footer";
import { useToast } from "@/components/ui/use-toast";
import axios from "axios";
import { Toaster } from "@/components/ui/toaster";
import { Button } from "@/components/ui/button";

function PuzzleScreen() {
  const puzzleId = useParams<{ id: string }>().id;
  const { toast } = useToast();
  const navigate = useNavigate();
  const [playedToday, setPlayedToday] = useState(false);
  const setDate = async () => {
    const response = await axios
      .post(`${import.meta.env.VITE_SERVER_URL}/`, {
        requestId: localStorage.getItem("requestId"),
      })
      .then((response) => {
        const lastPlayedDate = localStorage.getItem("lastPlayedDate");
        if (lastPlayedDate == response.data.data.currentDate) {
          toast({
            title: "Done for the day!",
            description: "Come back tomorrow!",
          });
          setPlayedToday(true);
        }
        return response.data;
      });
  };
  const setRestricted = async () => {
    const puzzleNumber = localStorage.getItem("puzzleNumber");
    if (puzzleNumber) {
      parseInt(puzzleNumber) < parseInt(puzzleId) ? navigate("/") : "";
    } else if (parseInt(puzzleId) != 1 && puzzleNumber == null) {
      navigate("/");
    }
  };
  useEffect(() => {
    if (parseInt(puzzleId) > 50) {
      return navigate("/");
    }
    setDate();
    setRestricted();
  }, [playedToday]);
  return (
    <div>
      <Header />
      {playedToday ? (
        <>
          <div>
            <div className="fixed inset-0 z-50 overflow-y-auto">
              <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div
                  className="fixed inset-0 transition-opacity"
                  aria-hidden="true"
                >
                  <div className="absolute inset-0 bg-gray-500 opacity-20"></div>
                </div>
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
                  &#8203;
                </span>
                <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                  <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        <svg
                          className="h-6 w-6 text-red-600"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          {" "}
                        </svg>
                      </div>
                      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                          Come back tomorrow!
                        </h3>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">
                            New Puzzles are released every day!
                          </p>
                        </div>
                        <div className="mt-4">
                          <Button
                            onClick={() => {
                              navigate("/");
                            }}
                            type="button"
                            className="ml-4 justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 text-sm font-medium text-red-800 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                          >
                            Go to home
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Toaster />
          </div>
        </>
      ) : (
        <>
          <Puzzle id={puzzleId} />
          <Footer />
        </>
      )}
    </div>
  );
}

export default PuzzleScreen;
