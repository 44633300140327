import { useEffect, useState } from "react";
import "./App.css";
import Footer from "./components/Footer";
import Header from "./components/Header";
import StartPuzzle from "./screens/StartPuzzle";
import "@fontsource/roboto";
import { v4 as uuidv4 } from "uuid";

function App() {
  if (!localStorage.getItem("requestId"))
    localStorage.setItem("requestId", uuidv4());
  return (
    <>
      {/* <Board/> */}
      <Header />
      <StartPuzzle />
      <Footer />
    </>
  );
}

export default App;
